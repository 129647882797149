// // local
// export const domain = "http://127.0.0.1:8000/api/";
// export const domainV2 = "http://127.0.0.1:8000/api2/";
// export const imageDomain = "http://127.0.0.1:8000/storage/";

// server test
export const domain = "https://acaapi.ksta.co/api/";
export const domainV2 = "http://acaapi.ksta.co/api2/";
export const imageDomain = "https://acaapi.ksta.co/storage/";

// Server Prod
// export const domain = "https://elearningapi.ordpmk.com/api/";
// export const domainV2 = "https://elearningapi.ordpmk.com/api2/";
// export const imageDomain = "https://elearningapi.ordpmk.com/storage/";