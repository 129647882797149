var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-5",attrs:{"id":"sideMenu"}},[_c('div',{staticClass:"text-left",attrs:{"shadow":""}},[_vm._m(0),_c('div',{staticClass:"side-menu-border px-2 py-2 mt-2"},_vm._l((_vm.category),function(item){return _c('button',{key:item.id,class:`btn btn-outline-white btn-block side-menu text-left pl-0 btnHover ${
          _vm.$router.currentRoute.params.id == item.id ? 'btnActive' : ''
        }`,attrs:{"href":"#"},on:{"click":function($event){return _vm.categorytClick(item.id)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0),_vm._m(1),_c('div',{staticClass:"side-menu-border px-2 py-2 mt-2"},_vm._l((_vm.group),function(item){return _c('button',{key:item.id,class:`btn btn-outline-white btn-block side-menu text-left pl-0 btnHover ${
          _vm.$router.currentRoute.query.id == item.id ? 'btnActive' : ''
        }`,attrs:{"href":"#"},on:{"click":function($event){return _vm.$router.push('/favorite-menu-book?id=' + item.id)}}},[_vm._v(" "+_vm._s(item.group_name)+" ")])}),0),_c('div',{staticClass:"side-menu-border px-2 py-2 mt-2"},[_c('button',{class:`btn btn-outline-white btn-block side-menu text-left pl-0 btnHover ${
          _vm.$router.currentRoute.name == 'allTeachers' ? 'btnActive' : ''
        }`,on:{"click":function($event){return _vm.categorytClick('allTeachers')}}},[_vm._v(" ผู้สอนทั้งหมด ")])]),_c('div',{staticClass:"side-menu-border px-2 py-2 mt-2"},[_c('button',{class:`btn btn-outline-white btn-block side-menu text-left pl-0 btnHover ${
          _vm.$router.currentRoute.name == 'allCourses' ? 'btnActive' : ''
        }`,on:{"click":function($event){return _vm.categorytClick('allCourses')}}},[_vm._v(" คอร์สเรียนทั้งหมด ")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"font-size":"24px"}},[_c('b',[_vm._v("หมวดหมู่")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"font-size":"24px"}},[_c('b',[_vm._v("E-Book")])])
}]

export { render, staticRenderFns }