<template>
  <div id="sideMenuMobile">
    <div v-b-toggle.sidebar-1><b-icon-list-ul></b-icon-list-ul>หมวดหมู่</div>
    <b-sidebar id="sidebar-1" class="" title="หมวดหมู่" shadow>
      <div class="px-3 py-2">
        <div class="pointer" v-for="item in category" :key="item.id">
          <a @click="categorytClick(item.id)">{{ item.name }}</a>
          <hr />
        </div>

        <div class="pointer" v-for="item in group" :key="item.id">
          <a @click="$router.push('/favorite-menu-book?id=' + item.id)">
            {{ item.group_name }}
          </a>
        </div>

        <div class="my-10"></div>
        <div class="pointer">
          <hr />
          <a @click="categorytClick('allTeachers')">ผู้สอนทั้งหมด</a>
          <hr />
        </div>
        <div class="pointer">
          <a @click="categorytClick('allCourses')">คอร์สเรียนทั้งหมด</a>
          <hr />
        </div>
      </div>
    </b-sidebar>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "sideMenuMobile",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      category: (state) => state.Category.category,
      group: (state) => state.Group.group,
    }),
  },
  created() {
    this.getCategory();
  },
  methods: {
    async getCategory() {
      await this.$store.dispatch("Category/getCategory");
    },
    async getGroup() {
      await this.$store.dispatch("Group/getGroup");
    },
    async categorytClick(id) {
      this.isLoading = true;

      if (id == "allCourses") {
        let path = `/all-courses`;
        this.isLoading = false;
        await this.$router.push({
          path: path,
        });
      } else if (id == "allTeachers") {
        let path = `/all-teachers`;
        this.isLoading = false;
        await this.$router.push({
          path: path,
        });
      } else if (id == "favoriteMenuEbook") {
        let path = `/favorite-menu-book/${id}`;
        this.isLoading = false;
        await this.$router.push({
          path: path,
        });
      } else {
        // await this.$store.dispatch("Courses/getAllCourse", {
        //   category: id,
        //   keyword: null,
        // });
        let path = `/category/${id}`;
        this.isLoading = false;
        await this.$router.push({
          path: path,
        });
      }

      this.isLoading = false;
    },
  },
};
</script>
<style>
/*@media screen and (min-width: 992px) {*/
/*  #sideMenuMobile {*/
/*    display: none !important;*/
/*  }*/
/*}*/
</style>
