<template>
  <b-card
    border-variant="secondary"
    :img-src="image"
    img-alt="Image"
    img-top
    tag="article"
    @click="goCourse(id)"
    class="card-article card-course"
    style="border-radius: 15px; height: 100%; overflow: hidden"
    
  >
    <div class="card-title" v-text="header" :title="header" ></div>
    <div class="short-desc" v-html="content" :title="replacedText"></div>
    <template #footer>
  <!-- <div class="enroll">{{ enroll }}</div> -->

    <div v-if="paid_type == 'paid' && status != 'paid' ">

      <div v-if="status == 'waiting' || status == 'pending' " >
        <span class="price-course">฿{{ price }}</span>
        <div class="d-flex justify-content-center">
          <b-button  class="w-100 m-1 z-3">กําลังดําเนินการ</b-button>
        </div>
      </div>
 
      <div v-else @click.stop="checkCoursepPaidType(id)">
        <span class="price-course">฿{{ price }}</span>
        <div class="d-flex justify-content-center">
          <b-button class="w-100 m-1 z-3" :disabled="isDisable()">ซื้อคอร์สนี้</b-button>
        </div>
      </div>
    </div>
  </template>
  </b-card>
 
</template>

<script>
export default {
  props: ["header", "content", "image", "id","paid_type","price","status","approve_right","access_rights","enroll"],
  computed: {
    replacedText() {
      return this.content
        .replace(/<p>/g, '')
        .replace(/<\/p>/g, '');
    },
    
  },
  methods: {
    async goCourse(id) {
      const path = `/courses`;
      await this.$router.push({ path: path, query: { id: id } });
    },

    checkCoursepPaidType(id) {
      this.$emit('open-modal',id);
    }
    ,isDisable(){
      let isDisable = false;
      if(!this.enroll){
        isDisable = true
      }else{
        if(this.access_rights === 'public'){
          isDisable = false
        }else if(this.access_rights === 'private' && this.approve_right){
          isDisable = false
        }else{
          isDisable = true
        }
      }


      return isDisable;

    }
  },
};
</script>

<style>
.card-title {
  font-size: 18px;
  font-weight: bold;
}

.price-course{
    font-weight: 700;
    font-size: 20px;
}
</style>
