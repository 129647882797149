<template>
  <div id="sideMenu" class="mt-5">
    <div class="text-left" shadow>
      <p style="font-size: 24px" class=""><b>หมวดหมู่</b></p>
      <div class="side-menu-border px-2 py-2 mt-2">
        <button
          v-for="item in category"
          :key="item.id"
          @click="categorytClick(item.id)"
          href="#"
          :class="`btn btn-outline-white btn-block side-menu text-left pl-0 btnHover ${
            $router.currentRoute.params.id == item.id ? 'btnActive' : ''
          }`"
        >
          {{ item.name }}
        </button>
      </div>
      <p style="font-size: 24px" class=""><b>E-Book</b></p>
      <div class="side-menu-border px-2 py-2 mt-2">
        <button
          v-for="item in group"
          :key="item.id"
          @click="$router.push('/favorite-menu-book?id=' + item.id)"
          href="#"
          :class="`btn btn-outline-white btn-block side-menu text-left pl-0 btnHover ${
            $router.currentRoute.query.id == item.id ? 'btnActive' : ''
          }`"
        >
          {{ item.group_name }}
        </button>
      </div>
      <div class="side-menu-border px-2 py-2 mt-2">
        <button
          @click="categorytClick('allTeachers')"
          :class="`btn btn-outline-white btn-block side-menu text-left pl-0 btnHover ${
            $router.currentRoute.name == 'allTeachers' ? 'btnActive' : ''
          }`"
        >
          ผู้สอนทั้งหมด
        </button>
      </div>
      <div class="side-menu-border px-2 py-2 mt-2">
        <button
          @click="categorytClick('allCourses')"
          :class="`btn btn-outline-white btn-block side-menu text-left pl-0 btnHover ${
            $router.currentRoute.name == 'allCourses' ? 'btnActive' : ''
          }`"
        >
          คอร์สเรียนทั้งหมด
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "sideMenu",
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      category: (state) => state.Category.category,
      group: (state) => state.Group.group,
    }),
  },
  created() {
    this.getCategory();
    console.log(this.$router.currentRoute);
    this.getGroup();
  },

  methods: {
    async getCategory() {
      await this.$store.dispatch("Category/getCategory");
    },
    async getGroup() {
      await this.$store.dispatch("Group/getGroup");
    },
    async categorytClick(id) {
      this.isLoading = true;

      if (id == "allCourses") {
        let path = `/all-courses`;
        this.isLoading = false;
        await this.$router.push({
          path: path,
        });
      } else if (id == "allTeachers") {
        let path = `/all-teachers`;
        this.isLoading = false;
        await this.$router.push({
          path: path,
        });
      } else if (id == "favoriteMenuEbook") {
        let path = `/favorite-menu-book/${id}`;
        this.isLoading = false;
        await this.$router.push({
          path: path,
        });
      } else {
        // await this.$store.dispatch("Courses/getAllCourse", {favorite-menu-book
        //   category: id,
        //   keyword: null,
        // });
        let path = `/category/${id}`;
        this.isLoading = false;
        await this.$router.push({
          path: path,
        });
      }

      this.isLoading = false;
    },
  },
};
</script>
<style>
@media screen and (max-width: 992px) {
  #sideMenu {
    display: none;
  }
}

/*button.side-menu:hover {*/
/*  background-color: #2c3143; !* Green *!*/
/*  color: white;*/
/*}*/
</style>
